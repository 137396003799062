import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/ProfileStyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import { authenticate, generateOTP, verifyOTP } from '../helper/helper';
import { useAuthStore } from '../store/store';
import { useOTP } from '../../context/OTPContext';
import Map from '../../assets/images/map.jpg';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

export default function RecoveryPage() {
  const navigate = useNavigate();
  const { setIsOTPVerified } = useOTP();
  const [username, setUsernameLocal] = useState('');
  const setUsername = useAuthStore((state) => state.setUsername);
  const [OTP, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpRequestCount, setOtpRequestCount] = useState(0);
  const [lastOtpRequestTime, setLastOtpRequestTime] = useState(null);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [cooldownActive, setCooldownActive] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const COOLDOWN_DURATION = 60 * 1000;

  useEffect(() => {
    if (cooldownActive && cooldownTime > 0) {
      const interval = setInterval(() => {
        setCooldownTime((prev) => {
          const newTime = prev - 1000;
          if (newTime <= 0) {
            setCooldownActive(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [cooldownTime, cooldownActive]);

  async function handleUsernameValidation() {
    if (!username.trim()) {
      setIsValidated(false);
      toast.error('Enter a valid username before validation.');
      return;
    }

    setLoading(true);
    try {
      const isValid = await authenticate(username);
      if (isValid) {
        await generateAndSendOTP(username);
      } else {
        setIsValidated(false);
        toast.error('Please enter a valid username.');
      }
    } catch (error) {
      toast.error('Error validating username.');
    } finally {
      setLoading(false);
    }
  }

  async function generateAndSendOTP(username) {
    const currentTime = Date.now();
    if (otpRequestCount >= 3 && currentTime - lastOtpRequestTime < COOLDOWN_DURATION) {
      const remainingTime = Math.ceil((COOLDOWN_DURATION - (currentTime - lastOtpRequestTime)) / 1000);
      toast.error(`Please wait ${remainingTime} seconds before requesting a new OTP.`);
      setCooldownTime(COOLDOWN_DURATION - (currentTime - lastOtpRequestTime));
      setCooldownActive(true);
      return;
    }

    setLoading(true);
    try {
      const generatedOTP = await generateOTP(username);
      if (generatedOTP) {
        setIsValidated(true);
        toast.success('OTP has been sent to your email.');
        setOtpRequestCount((prev) => prev + 1);
        setLastOtpRequestTime(currentTime);
        setCooldownTime(COOLDOWN_DURATION);
        setCooldownActive(true);
      } else {
        toast.error('Could not send OTP.');
      }
    } catch (error) {
      console.log(error);
      setIsValidated(false);
      toast.error('Error generating and sending OTP.');
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    if (OTP.length !== 6) {
      toast.error('OTP must be 6 digits.');
      return;
    }

    setLoading(true);
    try {
      const { status } = await verifyOTP({ username, code: OTP });
      if (status === 201) {
        setIsOTPVerified(true);
        setUsername(username);
        navigate('/resetpage');
      } else {
        toast.error('Wrong OTP. Check your email and try again.');
      }
    } catch (error) {
      toast.error('Error verifying OTP.');
    } finally {
      setLoading(false);
    }
  }

  function resendOTP() {
    generateAndSendOTP(username);
  }

  const handleOTPChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOTP(value);
    }
  };

  return (
    <div className='login'>
      <div className="container mx-auto">
        <Toaster position='top-center' reverseOrder={false} />
        <div className='flex justify-center items-center h-screen'>
          <div className="flex flex-col w-[70%]">
            {isValidated && (
              <div
                className='backbutton text-lg ml-[6rem] mb-4 w-[2rem] static left-0 text-gray-700 cursor-pointer'
                onClick={() => setIsValidated(false)}
              >
                <ArrowBack sx={{ width: 30, height: 30 }} />
              </div>
            )}
            <div className="title flex flex-col items-center justify-between">
              <h4 className='text-5xl font-bold'>Recovery</h4>
              <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
                {isValidated ? 'Enter OTP to recover password' : 'Enter your username to validate'}
              </span>
            </div>
            <div className="form flex flex-col items-center">
              {!isValidated ? (
                <div className='innerContainer reset'>
                  <FormControl sx={{ m: 3, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                    <OutlinedInput
                      onChange={(e) => setUsernameLocal(e.target.value)}
                      value={username}
                      sx={{ borderRadius: 2 }}
                      id="outlined-adornment-username"
                      type="text"
                      label="Username"
                    />
                  </FormControl>
                  <button
                    className={`bg-[#1D4ED8] text-white px-14 py-2 w-[100%] rounded-lg `}
                    onClick={handleUsernameValidation}
                    disabled={loading || cooldownActive}
                  >
                    {loading ? 'Validating...' : cooldownActive ? `Wait ${Math.ceil(cooldownTime / 1000)}s` : 'Send OTP via Mail'}
                  </button>
                </div>
              ) : (
                <>
                  <FormControl sx={{ m: 3, width: '60%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-otp">OTP</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-otp"
                      value={OTP}
                      onChange={handleOTPChange}
                      sx={{ borderRadius: 2 }}
                      label="OTP"
                      type="text"
                      inputProps={{ maxLength: 6 }}
                    />
                  </FormControl>
                  <button
                    className={`bg-[#1D4ED8] text-white px-14 py-2 w-[60%] rounded-lg mt-5`}
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Recovering...' : 'Recover'}
                  </button>
                  {cooldownTime < 1 && (
                    <div className="resendContainer text-center py-4">
                      <span className='text-gray-500'>
                        Can't get OTP? <button onClick={resendOTP} className='text-red-500'>Resend</button>
                      </span>
                    </div>
                  )}
                  {cooldownTime > 0 && (
                    <div className="cooldownMessage text-red-500 mt-4">
                      <span>Please wait {Math.ceil(cooldownTime / 1000)} seconds before requesting a new OTP.</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <img src={Map} alt="" />
    </div>
  );
}
