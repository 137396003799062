
import React, { createContext, useContext, useState } from 'react';

const OTPContext = createContext();

export const OTPProvider = ({ children }) => {
  const [isOTPVerified, setIsOTPVerified] = useState(false);

  return (
    <OTPContext.Provider value={{ isOTPVerified, setIsOTPVerified }}>
      {children}
    </OTPContext.Provider>
  );
};

export const useOTP = () => useContext(OTPContext);
