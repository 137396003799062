import React, { useRef, useState, } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import { verifyPassword } from '../helper/helper';
import avatar from '../assets/avatar.png';
import styles from '../../styles/ProfileStyle.module.css';
import { useAuthStore } from '../store/store';
import Cookies from 'js-cookie';
import '../../styles/Login.Screen.css'
import Map from '../../assets/images/map.jpg'
// import {  VisibilityOutlinedIcon, VisibilityOffOutlinedIcon } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';


const LoginPasswordPage = () => {
  const [username, setUsernameLocal] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const setUsername = useAuthStore((state) => state.setUsername);
  const [showPassword, setShowPassword] = useState(false);
  const [rToken,setRToken] = useState('')

  const handleLogin = async () => {

     try {
       const response = await verifyPassword({ username, password,rToken });
       console.log(response.data);
      if (response.data) {
        let {token} = response.data
        // localStorage.setItem('token', token)
        Cookies.set('token', token, { expires: 7, secure: true, sameSite: 'strict' }); // Set cookie with options
        setUsername(username);
        navigate('/app'); 
      } else {
        toast.error('Login failed. Username or password is incorrect');
      }
    } catch (error) {
      console.error('Error during login:', error);
      
      toast.error('Login failed');
    }

  };
  
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return(
    <div className='login'>
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <div className='innerContainer px-30 sm:px-10'>
      <div className="title flex flex-col items-center">
          <h4 className='text-5xl font-bold'>Login</h4>
          <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
            Explore More by connecting with us.
          </span>
        </div>
        <div className="textbox flex flex-col items-center justify-around gap-2 relative">
        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          <InputLabel  htmlFor="outlined-adornment-username">Username</InputLabel>
          <OutlinedInput
          sx={{borderRadius:2}}
            onChange={(e)=>setUsernameLocal(e.target.value)}
            value={username}
            id="outlined-adornment-username"
            type="text"
            label="Username"
          />
        </FormControl>          

          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
          sx={{borderRadius:2}}
            onChange={(e)=>setPassword(e.target.value)}
            value={password}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPassword(!showPassword)}
                  
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlinedIcon /> : < VisibilityOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <div className='w-full'>

        <button className="bg-[#1D4ED8] text-white px-14 py-2 w-full rounded-lg" onClick={handleLogin}>Login →</button>
        </div>
        <div className="text-center py-4 ">
         <span className='text-gray-500 text-base'>Don't have an Account? <Link className='text-red-500' to="/request-access">Request Access</Link></span> <br/>
         <span className='text-gray-500'>Forgot Password? <Link className='text-red-500' to="/recoverypage">Recover Now</Link></span>
        </div>
        <GoogleReCaptcha
          onVerify={token => {
          
            setRToken(token);
          }}
        />
          </div>
      </div>
      <img className='sm:hidden lg:block' src={Map} alt =""/>
    </div> 
  )
  
};

export default LoginPasswordPage;



// return (
//   <div className="container mx-auto">
//      <Toaster position='top-center' reverseOrder={false}></Toaster>
    
//      <div className='flex justify-center items-center h-screen'>
//       <div className={styles.glass}>

      // <div className="title flex flex-col items-center">
      //     <h4 className='text-5xl font-bold'>Hello</h4>
      //     <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
      //       Explore More by connecting with us.
      //     </span>
      //   </div>

//         <div className='profile flex justify-center py-4'>
//                 <img src={avatar} className={styles.profile_img} alt="avatar" />
//             </div>

    
//   </div>
//   </div>
//   </div>
// );