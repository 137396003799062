
import { Navigate, useNavigate, useLocation} from "react-router-dom";
import { useAuthStore } from "../store/store";
import Cookies from 'js-cookie';

export const AuthorizeUser = ({children}) => {
    // const token = localStorage.getItem('token')
    const token = Cookies.get('token');
    if(!token){
        return <Navigate to={'/'} replace={true}></Navigate>
    }

    return children;
}

export const ProtectRoute = ({children}) => {
    const username = useAuthStore.getState().auth.username;
    if(!username){
        return <Navigate to={'/'} replace={true}></Navigate>
    }
    return children;
}

