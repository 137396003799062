import React from 'react';
import { Navigate } from 'react-router-dom';
import { useOTP } from '../../context/OTPContext';
import ResetPage from './ResetPage';

const ProtectedResetPage = () => {
  const { isOTPVerified } = useOTP();

  return isOTPVerified ? <ResetPage /> : <Navigate to="/recoverypage" replace={true} />;
};

export default ProtectedResetPage;