import React, { useState } from 'react';
import styles from '../../styles/ProfileStyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/store';
import { resetPasswordValidation } from '../helper/validate';
import { resetPassword } from '../helper/helper';
import Map from '../../assets/images/map.jpg';
import '../../styles/Login.Screen.css';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function passwordVerify(errors = {}, values) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const capitalLetter = /[A-Z]/;

  if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.includes(" ")) {
    errors.password = toast.error("Wrong Password...!");
  } else if (values.password.length < 8) {
    errors.password = toast.error("Password must be more than 8 characters long");
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have at least one special character");
  }
  return errors;
}

export default function ResetPage() {
  const username = useAuthStore((state) => state.auth.username);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [errors, setErrors] = useState({});

  const isPasswordMatch = password === confirmPwd;

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate input
    let validationErrors;
    
    // Perform additional password validation
    validationErrors = passwordVerify(validationErrors, { password });
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop execution if there are validation errors
    }
  
    try {
      setIsLoading(true);
  
      const resetPromise = resetPassword({ username, password });
  
      toast.promise(resetPromise, {
        loading: 'Updating..!',
        success: <b>Reset Successful!</b>,
        error: (err) => {
          if (err.error.response) {
            const message = err.error.response.data.message || 'An error occurred during password reset';
            setErrors({
              ...errors,
              global: message,
            });
            return <b>{message}</b>;
          } else {
            setErrors({
              ...errors,
              global: 'An unexpected error occurred, try again',
            });
            return <b>An unexpected error occurred, try again</b>;
          }
        },
      });
  
      await resetPromise;
      toast.success('Password reset successfully');
      navigate('/');
    } catch (error) {
      console.error('An error occurred during password reset:', error);
      if (!error.response) {
        setErrors({
          ...errors,
          global: 'An unexpected error occurred, try again',
        });
        toast.error('An unexpected error occurred, try again');
      } else {
        toast.error(error.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="login">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="innerContainer">
        <div className="flex justify-center items-center h-screen">
          <div className="flex justify-center items-center flex-col">
            <div className="title flex flex-col items-center">
              <h4 className="text-5xl font-bold">Reset</h4>
              <span className="py-4 text-xl w-full text-center text-gray-500">Enter new password.</span>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="textbox flex flex-col items-center">
                <FormControl sx={{ m: 2, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    sx={{ borderRadius: 2 }}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-confirm-pwd">Confirm Password</InputLabel>
                  <OutlinedInput
                    onChange={(e) => setConfirmPwd(e.target.value)}
                    value={confirmPwd}
                    sx={{ borderRadius: 2 }}
                    id="outlined-adornment-confirm-pwd"
                    type={showCPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowCPassword(!showCPassword)}
                          edge="end"
                        >
                          {showCPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
               
                <button
                  style={{ background: '#1D4ED8' }}
                  className={`bg-[#1D4ED8] text-white px-14 py-2 w-full rounded-lg mt-5 ${!isPasswordMatch ? styles.submitbtn : ''}`}
                  type="submit"
                  disabled={isLoading || !isPasswordMatch}
                >
                  {isLoading ? 'Submitting...' : 'Reset'}
                </button>
                <ul className="list-disc text-sm text-gray-400 mt-2 ml-6">
                  <li>Password must be more than 8 characters long.</li>
                  <li>Password must have at least one special character.</li>
                  <li>Password must have at least one capital letter.</li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
      <img src={Map} alt="" />
    </div>
  );
}
