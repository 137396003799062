import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import LoginPasswordPage from './authentication/Screens/LoginPasswordPage';
import reportWebVitals from './reportWebVitals';
import RecoveryPage from './authentication/Screens/RecoveryPage';
import ProtectedResetPage from "./authentication/Screens/ProtectedRestPage"
import Register from './authentication/Screens/Register';
import "./index.css";
import { AuthorizeUser } from './authentication/middleware/auth';
import { KeyProvider } from './context/KeyContext';
import Cookies from 'js-cookie';
import api from './config/api';
import { OTPProvider } from './context/OTPContext';
import RequestAccess from './authentication/Screens/RequestAccess';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import NotFound from './Screens/404';
import DownloadPage from './Screens/DownloadPage'; // Import the DownloadPage component

const Root = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 500);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  if(isMobile){
  
    return(<DownloadPage />)
   }
  return (
            
    <KeyProvider>
      <OTPProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <GoogleReCaptchaProvider reCaptchaKey='6LdRhikqAAAAAJKWMYxl6KTxIfzpc4PDVbKaxICx'>
                <LoginPage />
              </GoogleReCaptchaProvider>
            } />
            <Route path="/recoverypage" element={<RecoveryPage />} />
            <Route path="/request-access" element={<RequestAccess />} />
            <Route path="/register/:params" element={<Register />} />
            <Route path="/resetpage" element={<ProtectedResetPage />} />
            <Route path="/app" element={<ProtectedApp />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </OTPProvider>
    </KeyProvider>
  );
};

const ProtectedApp = () => (
  <AuthorizeUser>
    <App />
  </AuthorizeUser>
);

const LoginPage = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const token = Cookies.get('token');
      if (token) {
        try {
          const response = await api.post('/verifyToken', {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // or any loading indicator you prefer
  }

  return isAuthenticated ? <Navigate to={'/app'} replace={true} /> : <LoginPasswordPage />;
};

ReactDOM.render(<Root />, document.getElementById('root'));

reportWebVitals();
