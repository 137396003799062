import React, { useState, useEffect } from 'react';

const DownloadPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust based on your definition of mobile screen size
    };

    checkScreenSize(); // Check on component mount
    window.addEventListener('resize', checkScreenSize); 

    return () => window.removeEventListener('resize', checkScreenSize); 
  }, []);

  if (!isMobile) {
    return null; 
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 text-center bg-gray-100">
      <h1 className="text-3xl font-bold mb-4">Get the App</h1>
      <p className="text-lg mb-8">Download our app from the Play Store or App Store</p>
      <div className="flex gap-4">
        <a
          href="https://play.google.com/store/apps/details?id=your.app.id"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
            alt="Google Play Store"
            className="w-48"
          />
        </a>
        <a
          href="https://apps.apple.com/app/idyourappid"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
            alt="Apple App Store"
            className="w-48"
          />
        </a>
      </div>
        <p className="text-base text-gray-400 mt-8">or access using Desktop Site</p>
    </div>
  );
};

export default DownloadPage;
