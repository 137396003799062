import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../config/api';

const RequestAccess = () => {
  const [countries, setCountries] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyURL, setCompanyURL] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

    useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await axios.get('https://restcountries.com/v2/all');
            setCountries(response.data);
          } catch (error) {
            console.error('Error fetching countries:', error);
          }
        };
      
        fetchCountries();
      }, []);

    const handleSubmit = async () => {
        if (
            !companyName ||
            !companyURL ||
            !firstName ||
            !lastName ||
            !adminEmail ||
            !username ||
            !password ||
            !mobile ||
            !address ||
            !city ||
            !country ||
            !state ||
            !zipcode ||
            !agreeToTerms
          ) {
            alert('Please fill out all the required fields before submitting.');
            return;
          }

           // Validation for email, mobile, and password fields
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

    if (!emailRegex.test(adminEmail)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!mobileRegex.test(mobile)) {
      alert('Please enter a valid mobile number.');
      return;
    }

    if (!passwordRegex.test(password)) {
      alert('Password must be 8 characters long, contain at least 1 special character, and at least 1 capital letter.');
      return;
    }
    
        const userData = {
          companyName,
          companyURL,
          firstName,
          lastName,
          adminEmail,
          username,
          password,
          mobile,
          address,
          city,
          country,
          state,
          zipcode,
          agreeToTerms,
        };
      
        try {
            const response = await api.post('/request-access', userData, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
        
            if (response.status === 200) {
              toast.success('Request submitted successfully', { autoClose: 3000 });
              // Additional logic or UI updates on successful submission
            } else {
                toast.error(`Error submitting data: ${response.statusText}`);
            }
        } catch (error) {
              toast.error('Server Error');

          }
        };

      return (
        <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
          <div className="container max-w-screen-lg mx-auto">
            <div>
              <h1 className="font-bold text-3xl text-gray-600">Request Enterprise Access</h1>
              <br />
              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 text-left">
                  <div className="text-gray-600">
                    <p className="font-medium text-lg">Company Details</p>
                    <p>Please fill out all the fields.</p>
                    <br/>
                    <p className="font-medium text-lg">Admin Details</p>
                    <p>Enter Admin Information.</p>
                  </div>
      
                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                      <div className="md:col-span-3">
                        <label htmlFor="full_name">Company Name</label>
                        <input
                          type="text"
                          id="full_name"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="EasyRoute"
                          onChange={(e) => setCompanyName(e.target.value)}
                          value={companyName}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="url">Company URL</label>
                        <input
                          type="url"
                          id="website"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="ezroute.us"
                          onChange={(e) => setCompanyURL(e.target.value)}
                          value={companyURL}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          type="text"
                          id="firstname"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="John"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                          type="text"
                          id="lastname"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="Doe"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-3">
                        <label htmlFor="email">Admin Email Address</label>
                        <input
                          type="text"
                          id="email"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="email@ezroute.us"
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          title="Please enter a valid email address"
                          onChange={(e) => setAdminEmail(e.target.value)}
                          value={adminEmail}
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="username">Username</label>
                        <input
                          type="text"
                          id="username"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="johndoe"
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="•••••••••"
                          pattern="(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}"
                          title="Password must be 8 characters long, contain at least 1 special character, and at least 1 capital letter"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="mobile">Mobile Number</label>
                        <input
                          type="text"
                          id="mobile"
                          className="h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder="e.g. 999-999-9999"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          title="Please enter a valid mobile number"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          required
                        />
                      </div>
      
                      <div className="md:col-span-3">
                        <label htmlFor="address">Address / Street</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          value={address}
                          placeholder=""
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="city">City</label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          value={city}
                          placeholder=""
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
      
                      <div className="md:col-span-2 relative">
                        <label htmlFor="country">Country / Region</label>
                        <div className="h-10 bg-gray-50 flex border border-gray-200 rounded-lg items-center mt-1 relative">
                          <select
                            id="country"
                            className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent"
                            onChange={(e) => setCountry(e.target.value)}
                            value={country}
                          >
                            <option value="" disabled>
                              Select a country
                            </option>
                            {countries.map((country) => (
                              <option key={country.alpha2Code} value={country.alpha2Code}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
      
                      <div className="md:col-span-2">
                        <label htmlFor="state">State / Province</label>
                        <div className="h-10 bg-gray-50 flex border border-gray-200 rounded-lg items-center mt-1">
                          <input
                            id="state"
                            placeholder=""
                            className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />


                        </div>
                      </div>
      
                      <div className="md:col-span-1">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          type="text"
                          id="zipcode"
                          className="transition-all flex items-center h-10 border mt-1 rounded-lg px-4 w-full bg-gray-50"
                          placeholder=""
                          onChange={(e) => setZipcode(e.target.value)}
                          value={zipcode}
                        />
                      </div>
      
                      <div className="flex items-start md:col-span-3 mt-1">
                        <div className="flex items-center h-5">
                        <input
                        id="remember"
                        type="checkbox"
                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                        className="w-4 h-4 border border-gray-300 rounded-lg bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        required
                        />
                        </div>
                        <label
                          htmlFor="remember"
                          className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          I agree with the{' '}
                          <button
                            type="button"
                            className="text-blue-600 hover:underline dark:text-blue-500"
                            onClick={{}}
                            >
                            terms and conditions
                            </button>
                          .
                        </label>
                      </div>
      
                      <div className="md:col-span-5 text-right">
                        <div className="inline-flex items-end">
                          <button
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      

};

export default RequestAccess;
