import React from 'react';
import { RxCrossCircled } from 'react-icons/rx';

const ExtendedScreen = ({ children, isExpanded, onToggleExpand }) => {

  return (
    <div
      className={`${
      isExpanded ? 'lg:w-[18rem] md:w-[18rem] lg:left-[275px]  md:left-[275px] left-[80px] opacity-100 ' : 'w-0 left-[-300px] opacity-0'
    } absolute top-0 right-0 bg-white text-black w-[300px] h-full p-2 z-10000 transition-opacity ease-out duration-700 `}
    >
      {isExpanded ? (
        <div>
          <div style={{width:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center' }}>
            <RxCrossCircled onClick={onToggleExpand}  className='cursor-pointer mt-4 mr-2 text-2xl' />
          </div>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default ExtendedScreen;
