import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import api from "../config/api";
// import { useCookies } from "react-cookie";

export const keyContext = createContext({
        googleMapsApiKey: '',
        secretkey: '',
})

const KeyProvider = ({children})=>{
    const [keys,setKeys] = useState({
        googleMapsApiKey: "",
        secretkey: "",
        isLoaded:false
      })

    useEffect(()=>{
        const getData = async ()=>{
            const response = await api.get('access')
            setKeys(response.data)
            setKeys(prevValue=>({...prevValue,isLoaded:true}))
            // setCookies('googleMapsApiKey',response.data.googleMapsApiKey)
            // setCookies('secretkey',response.data.secretkey)
        }
      
            getData()
     
    },[])
    return <keyContext.Provider value={keys}>
        {children}
    </keyContext.Provider>
}

export {KeyProvider}