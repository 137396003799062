import React from 'react'
import './404.css'
import Lost from '../assets/images/lost.svg'
import { ArrowBack } from '@mui/icons-material'
function NotFound() {
  return (
    <div className='notfound flex-col justify-evenly'>
        <h1 className='text-8xl'>404</h1>
        <img className='w-[20vw] m-10' src={Lost} alt='lost' />
        <p className='text-xl'>Oops! You should not be here!!!</p>
        <a href='/' className='margin-top-5 text-purple-500 text-2xl'><ArrowBack/> Go to Home</a>
    </div>
  )
}

export default NotFound