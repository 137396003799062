import React, { useEffect, useState, useMemo } from "react";
import { getRoute } from "../../components/fetch/fetchRoute";
import api from "../../config/api";

function SavedRoutes(props) {
  const [userRoutes, setUserRoutes] = useState([]);
  const { handlePolylinesUpdate, onSavedRouteClick, selectedLocation } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const routes = await getRoute(selectedLocation); // Fetch all routes for selected location
        setUserRoutes(routes.route);
      } catch (error) {
        // Handle errors
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [selectedLocation]);

  const handleonclick =  (route) => {
     handlePolylinesUpdate(route.Route);
    onSavedRouteClick(true);
  };

  const handleShowAll = () => {
    if (document.getElementById("showAllCheckbox").checked) {
      const updatedAllRoutes = userRoutes.map(route => route.Route);
      handlePolylinesUpdate(updatedAllRoutes);
    } else {
      handlePolylinesUpdate([]);
    }
  };

  const handleDeleteRoute = async (routeId) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this route?');
      if (!confirmDelete) return; // User canceled the deletion

      const response = await api.delete(`/deleteRoute/${routeId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // console.log('Delete Response:', response);

      if (response.status === 200) {
        setUserRoutes((prevRoutes) => prevRoutes.filter((route) => route._id !== routeId));
      } else {
        console.error('Failed to delete route:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting route:', error.message);
    }
  };

  const handleUpdateRoute = async (routeId, RouteName) => {
    try {
      const response = await api.patch(`/updateRoute/${routeId}`, { RouteName }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        throw new Error(`Update failed with status ${response.status}`);
      }

      setUserRoutes((prevRoutes) =>
        prevRoutes.map((route) =>
          route._id === routeId ? { ...route, RouteName: RouteName } : route
        )
      );

      console.log('Route updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating route name:', error.message);
    }
  };

  const memoizedRoutes = useMemo(() => {
    return userRoutes.map((route) => (
      <li key={route._id} className='border-b border-gray-200 overflow-y-auto p-1 text-lg font-normal hover:bg-gray-100 block'>
        <div onClick={() => handleonclick(route)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: "pointer" }}>
          <span>{route.RouteName}</span>
          <div>
            <button
              className="fa-solid fa-pencil px-4"
              onClick={() => {
                const newRouteName = prompt('Enter new route name:', route.RouteName);
                if (newRouteName !== null) {
                  handleUpdateRoute(route._id, newRouteName);
                }
              }}
            ></button>
            <button className="fa-solid fa-xmark" onClick={() => handleDeleteRoute(route._id)}></button>
          </div>
        </div>
      </li>
    ));
  }, [userRoutes]);

  return (
    <div className="p-1 leading-loose">
      <div className="ml-1">
        <input
          id="showAllCheckbox"
          type="checkbox"
          onChange={handleShowAll}
        />
        <span htmlFor="showAllCheckbox" className="ml-1 text-sm font-medium"> Show all saved routes</span>
      </div>
      <div className="mt-2">
        <ul>
          {memoizedRoutes}
        </ul>
      </div>
    </div>
  );
}

export default SavedRoutes;
